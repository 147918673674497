import React from 'react'
import { graphql } from 'gatsby'

import Gallery from 'components/Gallery'
import SiteMetadata from 'query/SiteMetadata'

const ExempelPaArbeten = ({ data }) => {
  const { email } = SiteMetadata()
  return (
    <Gallery query={data.allExempelPaArbetenYaml} heading='Exempel på arbeten'>
      <p>
        Om ni är intresserade av att beställa skyltar så kontakta mig gärna på:{' '}
        <oma-link to={email} class='link' />
      </p>
    </Gallery>
  )
}

export const query = graphql`
  {
    allExempelPaArbetenYaml {
      edges {
        node {
          title
          image {
            ...galleryImagesLarge
          }
        }
      }
    }
  }
`

export default ExempelPaArbeten
